<template>
    <div class="search">
        <StackRouterHeaderBar :show-title="true" title="단과대/학과로 차단" />
        <div class="title text-center" v-html="`학교명`" />
        <div class="input-wrapper items-center">
            <i class="material-icons i-search">search</i>
            <input
                ref="searchValue"
                v-model="searchValue"
                @input="onKeydown"
                :placeholder="$translate('PLACEHOLDER_SEARCH_UNIVERSITY')"
            />
            <i v-if="searchValue" @click="searchValue = null" class="material-icons">cancel</i>
        </div>
        <div class="items" v-if="universities">
            <div class="result-wrapper" v-if="searchResults">
                <div class="item" v-for="(res, idx) in searchResults" :key="idx" @click="onClickUniversity(res.id)">
                    <span v-html="res.name" />
                    <i class="material-icons">chevron_right</i>
                </div>
            </div>
            <div class="result-wrapper" v-else>
                <div
                    class="item"
                    :class="{ selected: item.selected }"
                    v-for="item in universities"
                    :key="item.id"
                    @click="onClickUniversity(item.id)"
                >
                    <span v-html="item.name" />
                    <i class="material-icons">chevron_right</i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import profileService from '@/services/profile'
export default {
    name: 'BlockCollegeSearch',
    data: () => ({
        universities: null,
        searchValue: null,
        searchResults: null,
    }),
    methods: {
        async init() {
            this.universities = await profileService.universitiesFromBlock()
            this.universities = this.universities.map(univ => ({
                ...univ,
                selected: false,
            }))
        },
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            }, 200)
        },
        search: debounce(async function () {
            this.searchResults = await profileService.universities({ name: this.searchValue })
            this.universities = this.searchResults
        }, 200),
        onClickUniversity(id) {
            this.universities = this.universities.map(univ => ({
                ...univ,
                selected: univ.id === id,
            }))
            setTimeout(async () => {
                await this.$modal.custom({
                    component: 'ModalCollegeSearch',
                    options: {
                        blockType: 'major',
                        university: this.universities.find(u => u.id === id).name,
                    },
                })
            }, 300)
        },
    },
    async mounted() {
        await this.init()
    },
}
</script>

<style scoped lang="scss">
.search {
    .main {
        padding: 0 16px;
    }
    .title {
        @include f-medium;
        color: black;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 24px;
        padding: 0 16px;
    }
    .item {
        @include between;
        padding: 18px 16px;
        font-size: 16px;
        color: black;
        border-bottom: 1px solid $grey-01;
        i {
            color: $grey-04;
        }

        &.selected {
            color: $blue-primary;
            @include f-bold;
            i {
                color: $blue-primary;
            }
        }
    }
    .input-wrapper {
        display: flex;
        flex-direction: row;
        width: calc(100% - 40px);
        height: 52px;
        border-radius: 8px;
        border: 1px solid $grey-02;
        padding: 12px 16px;
        margin: 0 20px 12px;

        .material-icons {
            font-size: $icon-size;
            color: $grey-05;
            margin: 0;
        }

        img {
            width: 24px;
            height: 24px;
        }

        .i-search {
            margin-right: 4px;
            color: $grey-08;
        }

        input {
            font-size: 15px;
            margin: 0;
            padding: 0;
            color: black;
        }

        input::placeholder {
            color: $grey-05;
        }
    }
}
</style>
